const guestBookings = [
  {
    path: '/qr/book/cancel',
    name: 'qrBook',
    component: () => import('@/views/bookings/BookingCancelView'),
    meta: {
      title: 'qr_book_cancel', allow_guest: true
    }
  },
  {
    path: '/qr/booking',
    name: 'guestBooking',
    component: () => import('@/views/guest-booking/GuestBookingView'),
    meta: {
      title: 'guest_booking', allow_guest: true
    },
    children: [
      {
        path: ':apartmentId/:hash',
        name: 'initGuestBooking',
        component: () => import('@/views/guest-booking/InitBookingView'),
        meta: {
          title: 'init_guest_booking', allow_guest: true
        },
      },
      {
        path: ':apartmentId/:hash/:timeHash',
        name: 'requestGuestBooking',
        component: () => import('@/views/guest-booking/RequestBookingView'),
        meta: {
          title: 'request_guest_booking', allow_guest: true
        },
      }
    ]
  }
]

export default guestBookings;