export default {
  namespaced: true,
  state: {
    electricityTotal: null
  },

  getters: {
    ELECTRICITY_TOTAL(state) {
      return state.electricityTotal;
    },
  },

  mutations: {
    CLEAR_DATA(state) {
      state.electricityTotal = null;
    }
  },

  actions: {
    GET_FILE(context, {apartmentID, filters, type}) {
      let routeName;

      switch (type) {
        case 'pdf':
          routeName = 'filePdf';
          break;
        case 'counterPdf':
          routeName = 'counterFilePdf';
          break;
        default:
          routeName = 'file';
          break;
      }

      return this.$api.get(`api/apartments/electricity/${apartmentID}/${routeName}`, {params: filters})
        .then((response) => {
          window.open(response.data);
        });
    },
    GET_ELECTRICITY_TOTAL(context) {
      if (!context.state.electricityTotal) {
        return this.$api.get('api/apartments/electricity/total', null, false).then((response) => {
          context.state.electricityTotal = response.data.data;
        });
      }
    },
  }
};
