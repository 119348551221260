export default {
  namespaced: true,
  state: {
    article: null,
    topics: [],
  },
  getters: {
    ARTICLE(state) {
      return state.article;
    },
    TOPICS(state) {
      return state.topics;
    }
  },
  mutations: {
    SET_ARTICLE(state, article) {
      state.article = article;
    },
    SET_TOPICS(state, topics) {
      state.topics = topics;
    },
    CLEAR_ARTICLE(state) {
      state.article = null;
    }
  },
  actions: {
    GET_ARTICLE(context, id) {
      return this.$api.get(`api/helpCenter/article/${id}`).then((response) => {
        context.commit('SET_ARTICLE', response.data.data);
      });
    },
    GET_TOPICS(context) {
      return this.$api.get('api/helpCenter/topics').then((response) => {
        context.commit('SET_TOPICS', response.data.data);
      });
    },
  }
};