const terms = [
  {
    path: '/terms',
    name: 'terms',
    component: () => import('@/views/terms/TermsView'),
    meta: {
      title: 'terms'
    },
  }
];

export default terms;
