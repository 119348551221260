export default {
  namespaced: true,
  state: {
    itemsList: {}
  },

  getters: {
    ITEMS_LIST(state) {
      return state.itemsList;
    },
  },

  mutations: {
    SET_ITEMS_LIST(state, itemsList) {
      state.itemsList = itemsList;
    },
    CLEAR_ITEMS_LIST(state) {
      state.itemsList = null;
    }
  },

  actions: {
    GET_LIST(context, payload) {
        return this.$api.get(`api/documents`, {params: {filters: payload}}).then((response) => {
          context.commit('SET_ITEMS_LIST', response.data.data);
        });
    },
    DOWNLOAD(context, itemID) {
      return this.$api.get(`api/documents/download/${itemID}`).then((response) => {
        window.open(response.data);
      });
    }
  }
};
