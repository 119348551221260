import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/i18n/en";
import { locale as ge } from "@/i18n/ge";
import { locale as ru } from "@/i18n/ru";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, ge, ru };

// get current selected language
const lang = localStorage.getItem("language") || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
