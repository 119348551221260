import LocalStorageService from '@/core/services/local-storage.service';

export default {
  namespaced: true,
  state: {
    user: null
  },

  getters: {
    USER(state) {
      return state.user;
    }
  },

  mutations: {
    DESTROY_USER(state) {
      state.user = null;
    }
  },

  actions: {
    GET_USER(context) {
      return this.$api.get('api/users').then((response) => {
        LocalStorageService.saveUser(response.data.data);
        context.state.user = response.data.data;
      });
    },
    CHANGE_LANGUAGE(context, lang) {
      return this.$api.put('api/users/locale', {locale: lang});
    },
    CHANGE_PASSWORD(context, dataToSubmit) {
      return this.$api.patch('api/users/password', dataToSubmit, true);
    }
  }
};
