const support = [
  {
    path: '/support',
    name: 'support',
    component: () => import('@/views/support/SupportView'),
    meta: {
      title: 'support'
    },
  }
];

export default support;
