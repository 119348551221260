import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// Global 3rd party plugins
import 'popper.js';
import 'tooltip.js';
import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;
import Notifications from "vue-notification";
import velocity from "velocity-animate";
Vue.use(Notifications, { velocity });

import VuePithyProgress from 'vue-pithy-progress'
import 'vue-pithy-progress/dist/vue-pithy-progress.css'
Vue.use(VuePithyProgress)

// Vue 3rd party plugins
import i18n from '@/core/plugins/vue-i18n';
import '@/core/plugins/portal-vue';
import '@/core/plugins/bootstrap-vue';
import '@/core/plugins/perfect-scrollbar';
import '@/core/plugins/inline-svg';
import '@/core/plugins/metronic';
import '@mdi/font/css/materialdesignicons.css';
import '@/core/plugins/formvalidation';

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
