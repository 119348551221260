export default {
  namespaced: true,
  state: {
    countries: [],
    details: null,
    events: [],
    forApartment: [],
    newCount: null,
    currentCount: null,
    orders: [],
    order: null,
    qrBookData: {}
  },

  getters: {
    COUNTRIES(state) {
      return state.countries;
    },
    DETAILS(state) {
      return state.details;
    },
    EVENTS(state) {
      return state.events;
    },
    FOR_APARTMENT(state) {
      return state.forApartment.length ? state.forApartment.sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()) : [];
    },
    NEW_COUNT(state) {
      return state.newCount;
    },
    CURRENT_COUNT(state) {
      return state.currentCount;
    },
    ORDERS(state) {
      return state.orders;
    },
    ORDER(state) {
      return state.order;
    },
    QR_BOOK_DATA(state) {
      return state.qrBookData;
    },
  },

  mutations: {
    SET_ORDERS(state, orders) {
      state.orders = orders;
    },
    SET_ORDER(state, order) {
      state.order = order;
    },
    SET_COUNTRIES(state, countries) {
      state.countries = countries;
    },
    SET_QR_BOOK_DATA(state, data) {
      state.qrBookData = data;
    },
    SET_EVENTS(state, events) {
      state.events = events && events.length
        ? events.map(el => {
          let className;
          switch (el.status) {
            case 'new':
              className = 'bg-warning-o-50';
              break;
            case 'approved':
              className = 'bg-success-o-50';
              break;
            case 'canceled':
              className = 'bg-danger-o-50';
              break;
          }
          return {
            ...el,
            content: /*el.status === 'new' ? '<span class="fas fa-circle text-danger calendar-event-dot"></span>' : */null,
            class: 'text-dark position-relative cursor-pointer ' + className
          }
        })
        : [];
    },
    CLEAR_DATA(state) {
      state.details = null;
      state.events = [];
      state.forApartment = [];
      state.newCount = null;
      state.currentCount = null;
    },
  },

  actions: {
    GET_COUNTRIES(context) {
      return this.$api.get('api/countries').then((response) => {
        context.commit('SET_COUNTRIES', response.data.data);
      });
    },
    GET_EVENTS(context, {filters, date_range}) {
      return this.$api.get('api/bookings/calendar', {params: {date_range, filters}}, false).then((response) => {
        context.commit('SET_EVENTS', response.data.data);
      });
    },
    GET_DETAILS(context, bookingID) {
      if (!context.state.keysCount) {
        return this.$api.get(`api/bookings/${bookingID}`).then((response) => {
          context.state.details = response.data.data;
        });
      }
    },
    GET_FOR_APARTMENT(context, apartmentID) {
      return this.$api.get(`api/apartments/${apartmentID}/bookings`, null, false).then((response) => {
        context.state.forApartment = response.data.data;
      });
    },
    GET_NEW_COUNT(context) {
      if (!context.state.newCount) {
        return this.$api.get(`api/bookings/new_count`, null, false).then((response) => {
          context.state.newCount = response.data.data;
        });
      }
    },
    GET_CURRENT_COUNT(context) {
      if (!context.state.currentCount) {
        return this.$api.get(`api/bookings/current_count`, null, false).then((response) => {
          context.state.currentCount = response.data.data;
        });
      }
    },
    GET_ORDERS(context, bookingId) {
      return this.$api.get(`api/services/${bookingId}/invoices`).then((response) => {
        context.commit('SET_ORDERS', response.data.data);
      });
    },
    GET_ORDER(context, orderId) {
      return this.$api.get(`api/services/invoices/${orderId}`).then((response) => {
        context.commit('SET_ORDER', response.data.data);
      });
    },
    GET_QR_BOOK_DATA(context, query) {
      return this.$api.get(`/api/qr/book/cancel`, {params: query}).then((response) => {
        context.commit('SET_QR_BOOK_DATA', response.data.data);
      });
    },
    BOOK(context, dataToSubmit) {
      return this.$api.post('api/bookings', dataToSubmit);
    },
    GENERATE_KEY(context, {bookingID, dataToSubmit}) {
      return this.$api.put(`api/bookings/${bookingID}`, dataToSubmit);
    },
    ACCEPT(context, {bookingID, dataToSubmit}) {
      return this.$api.patch(`api/bookings/${bookingID}`, dataToSubmit);
    },
    DECLINE(context, bookingID) {
      return this.$api.delete(`api/bookings/${bookingID}`);
    },
    CANCEL_QR_BOOK(context, {booking_id, token, lang}) {
      return this.$api.post('/api/qr/book/cancel', {booking_id, token, lang}, true);
    },
    EXPORT_HISTORY(context, filters) {
      return this.$api.get('api/bookings/file', {params: {filters: filters}}).then(response => {
        window.open(response.data);
      });
    },
    CONFIRM_PREDICT(context, bookingId) {
      return this.$api.get(`/api/bookings/confirmPredict/${bookingId}`);
    }
  }
};
