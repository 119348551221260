import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
import i18n from '@/core/plugins/vue-i18n';

import auth from './modules/auth';
import terms from './modules/terms';
import apartments from './modules/apartments';
import bookings from './modules/bookings';
import dashboard from './modules/dashboard';
import devices from './modules/devices';
import documents from './modules/documents';
import finances from './modules/finances';
import loyalty from './modules/loyalty';
import payments from './modules/payments';
import profile from './modules/profile';
import services from './modules/services';
import support from './modules/support';
import LocalStorageService from '../core/services/local-storage.service';

import guestBookings from './modules/guest/bookings';

Vue.prototype.$defaultRoute = 'support';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    ...terms,
    {
      path: '/',
      redirect: '/home',
      component: () => import('@/core/layout/Layout'),
      beforeEnter(to, from, next) {
        if (LocalStorageService.getAuthVerified() || to.meta.allow_guest) {
          next();
        } else {
          return next({name: 'login'});
        }
      },
      children: [
        ...apartments,
        ...bookings,
        ...dashboard,
        ...devices,
        ...documents,
        ...finances,
        ...loyalty,
        ...payments,
        ...profile,
        ...services,
        ...support,
        {
          path: '/test',
          name: 'test',
          component: () => import('@/views/Test.vue')
        },
        {
          path: '/deals',
          name: 'deals',
          component: () => import('@/views/Home.vue'),
          meta: {
            title: 'deals'
          },
        },
        {
          path: '/help',
          name: 'help',
          component: () => import('@/views/Help.vue'),
          meta: {
            title: 'help'
          },
        },
        {
          path: '/404',
          name: '404',
          component: () => import('@/views/error/404.vue')
        },
      ]
    },
    {
      path: '/',
      redirect: '/home',
      component: () => import('@/core/layout/GuestLayout'),
      children: [
        ...guestBookings
      ]
    },
    {
      path: '*',
      redirect: '404'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  const title = i18n.t(`navigation.${to?.meta?.title || to?.name}`);
  store.commit('subheader/SET_TITLE', title);
  Vue.nextTick(() => {
    document.title = title + ' | ORBI Group';
  });
  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
