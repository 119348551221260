import store from '../../store';
import LocalStorageService from '../../core/services/local-storage.service';

const auth = [
  {
    path: '/auth',
    redirect: '/login',
    component: () => import('@/views/auth/Auth'),
    children: [
      {
        path: '/verification',
        name: 'verification',
        component: () => import('@/views/auth/LoginVerification'),
        beforeEnter(to, from, next) {
          if (!LocalStorageService.getAccessToken() || !LocalStorageService.getRefreshToken()) {
            next('/login');
          } else {
            next();
          }
        },
        meta: {
          title: 'verification'
        },
      },
      {
        path: '/registration',
        name: 'registration',
        component: () => import('@/views/auth/Registration'),
        meta: {
          title: 'sign_up'
        },
      }
    ]
  },
  {
    path: '/auth',
    redirect: '/login',
    component: () => import('@/views/auth/NewAuth'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/auth/Login'),
        meta: {
          title: 'sign_in'
        },
      },
      {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: () => import('@/views/auth/ForgotPassword'),
        meta: {
          title: 'forgot_password'
        },
      },
      {
        path: '/reset-password',
        name: 'resetPassword',
        component: () => import('@/views/auth/ResetPassword'),
        meta: {
          title: 'reset_password'
        },
        beforeEnter: (to, from, next) => {
          if (to.query?.token) {
            next();
          } else {
            next({name: 'login'});
          }
        },
      }
    ]
  }
];

export default auth;
