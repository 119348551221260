const profile = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile'),
    meta: {
      title: 'profile'
    },
  }
];

export default profile;
