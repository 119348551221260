const apartments = [
  {
    path: '/apartments',
    name: 'apartmentsList',
    component: () => import('@/views/apartments/ApartmentsList'),
    meta: {
      title: 'my_apartments'
    },
  },
  {
    path: 'apartments/:id',
    name: 'apartmentShow',
    component: () => import('@/views/apartments/ApartmentShow'),
    meta: {
      title: 'apartment_details'
    },
  },
  {
    path: 'apartments/test/:id',
    name: 'apartmentTestShow',
    component: () => import('@/views/apartments/ApartmentTestShow'),
    meta: {
      title: 'apartment_details'
    },
  }
];

export default apartments;
