const payments = [
  {
    path: '/pay/:apartment_id/:type',
    name: 'pay',
    component: () => import('@/views/payments/Main'),
    meta: {
      title: 'pay'
    },
  }
];

export default payments;
