<template>
  <div class="app-root">
    <PageLoader v-if="isLoading"/>
    <AppNotify/>
    <router-view v-if="isLoaded"></router-view>
  </div>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";
@import "assets/plugins/fontawesome5/all.min.css";

// Main style scss
@import "assets/sass/style.vue";
@import "assets/sass/custom.css";
</style>

<script>
import {mapGetters} from 'vuex';
import {OVERRIDE_LAYOUT_CONFIG} from '@/store/core/config.module';
import PageLoader from '@/components/elements/loaders/PageLoader';
import AppNotify from '@/components/elements/notifications/AppNotify';
import i18nService from './core/services/i18n.service';

export default {
  name: "MetronicVue",
  components: {
    PageLoader,
    AppNotify,
  },
  data: () => ({
    isLoaded: false,
    unreadMessagesCountIntervalId: null
  }),
  mounted() {
    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);
  },
  computed: {
    ...mapGetters({
      isLoading: 'loader/IS_LOADING',
      user: 'usersStore/USER'
    })
  },
  watch: {
    user: {
      handler(data) {
        if (data) {
          if (!this.unreadMessagesCountIntervalId) {
            this.unreadMessagesCountIntervalId = setInterval(this.getNotificationsCount, 60*1000);
          }
          if (data.language !== i18nService.getActiveLanguage()) {
            this.changeLanguage(data.language);
          }
        } else {
          clearInterval(this.unreadMessagesCountIntervalId);
          this.unreadMessagesCountIntervalId = null;
        }
        this.isLoaded = true;
      },
      immediate: true
    }
  },
  methods: {
    getNotificationsCount() {
      this.$store.dispatch('notificationsStore/GET_COUNT');
    },
    changeLanguage(language) {
      i18nService.setActiveLanguage(language);
      window.location.reload();
    },
    async getUser() {
      await this.$store.dispatch('usersStore/GET_USER').catch(() => {
        this.isLoaded = true;
      });
    }
  }
};
</script>
