const documents = [
  {
    path: '/documents',
    name: 'documentsList',
    component: () => import('@/views/documents/DocumentsList'),
    meta: {
      title: 'documents'
    },
  }
];

export default documents;