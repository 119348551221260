export default {
  namespaced: true,
  state: {
    overdueCount: null,
    summary: null,
  },

  getters: {
    OVERDUE_COUNT(state) {
      return state.overdueCount;
    },
    SUMMARY(state) {
      return state.summary;
    },
  },

  mutations: {
    CLEAR_DATA(state) {
      state.overdueCount = null;
      state.summary = null;
    }
  },

  actions: {
    GET_OVERDUE_COUNT(context) {
      if (!context.state.overdueCount) {
        return this.$api.get('api/contracts/overdue_count', null, false).then((response) => {
          context.state.overdueCount = response.data.data;
        });
      }
    },
    GET_SUMMARY(context) {
      if (!context.state.summary) {
        return this.$api.get('api/contracts/summary', null, false).then((response) => {
          context.state.summary = response.data.data;
        });
      }
    },
  }
};
