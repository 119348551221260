export default {
  namespaced: true,
  state: {
    newBookingsCount: 0,
    notifications: [],
    bookings: []
  },

  getters: {
    NEW_BOOKINGS_COUNT(state) {
      return state.newBookingsCount;
    },
    NOTIFICATIONS(state) {
      return state.notifications;
    },
    BOOKINGS(state) {
      return state.bookings;
    }
  },

  mutations: {
    SET_BOOKINGS(state, bookings) {
      state.bookings = bookings;
    },
    SET_NEW_BOOKINGS_COUNT(state, count) {
      state.newBookingsCount = count;
    },
    SET_NOTIFICATIONS(state, count) {
      state.notifications = count;
    }
  },

  actions: {
    GET_COUNT(context) {
      return this.$api.get('api/notifications/count', null, false).then((response) => {
        context.commit('SET_NEW_BOOKINGS_COUNT', response.data.data?.bookings.count);
        context.commit('ticketsStore/SET_UNREAD_MESSAGES_COUNT', response.data.data?.messages.count, {root: true});
      });
    },
    GET_NOTIFICATIONS(context) {
      return this.$api.get('api/notifications', null, false).then((response) => {
        context.commit('SET_NOTIFICATIONS', response.data?.data);
      });
    },
    GET_BOOKINGS(context) {
      return this.$api.get('api/notifications/bookings').then((response) => {
        context.commit('SET_BOOKINGS', response.data.data);
      });
    },
    SET_NOTIFICATION_AS_READ(context, notificationId) {
      return this.$api.post(`api/notifications/${notificationId}`);
    }
  }
};
