import Vue from 'vue';
import router from '@/router';
import notify from '@/core/services/notify.service';
import LocalStorageService from '@/core/services/local-storage.service';

// action types
export const IS_AUTH = 'isAuth';
export const LOGIN = 'login';
export const VERIFY_DEVICE = 'verifyDevice';
export const VERIFY_AUTH = 'verifyAuth';
export const RESEND_CODE = 'verifyResend';
export const LOGOUT = 'logout';
export const REGISTRATION = 'registration';
export const RESET_PASSWORD = 'resetPassword';
export const CHECK_RESET_TOKEN = 'checkResetToken';
export const CHANGE_PASSWORD = 'changePassword';
export const TERMS_ACTIVATE = 'termsActivate';

const state = {
  accessToken: null,
  refreshToken: null,
  verificationNeeded: false,
};

const getters = {
  ACCESS_TOKEN(state) {
    return state.accessToken;
  },
  REFRESH_TOKEN(state) {
    return state.refreshToken;
  },
  VERIFICATION_NEEDED(state) {
    return state.verificationNeeded;
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, data) {
    state.accessToken = data;
  },
  SET_REFRESH_TOKEN(state, data) {
    state.refreshToken = data;
  },
  CLEAR_ACCESS_TOKEN(state) {
    state.accessToken = null;
  },
  CLEAR_REFRESH_TOKEN(state) {
    state.refreshToken = null;
  },
  SET_VERIFICATION_NEEDED(state, val) {
    state.verificationNeeded = val;
  }
};

const actions = {
  [REGISTRATION](context, dataToSubmit) {
    return this.$api.post('api/auth/registration', dataToSubmit, true).then(() => {});
  },
  [LOGIN](context, credentials) {
    context.commit('SET_VERIFICATION_NEEDED', false);
    
    return this.$api.post('api/auth/login', credentials, false).then((response) => {
      LocalStorageService.saveAccessToken(response.data.data.access_token);
      LocalStorageService.saveRefreshToken(response.data.data.refresh_token);
      this.$api.setHeader();

      if (response.data?.data?.code === -2) {
        context.commit('SET_VERIFICATION_NEEDED', true);
      } else if (response.data?.data.code === 1) {
        LocalStorageService.saveAuthVerified();
        router.push({ name: Vue.prototype.$defaultRoute }).catch(() => {});
      }
    });
  },
  [VERIFY_DEVICE](context, credentials) {
    return this.$api.post('api/verifyDevice', credentials).then((response) => {
      LocalStorageService.saveDeviceUuid(response.data.data.device_uuid);
    });
  },
  [VERIFY_AUTH](context, credentials) {
    return this.$api.post('api/verifyAuth', credentials);
  },
  [RESEND_CODE](context) {
    return this.$api.post('api/verifyResend');
  },
  [LOGOUT](context) {
    LocalStorageService.destroyUser();
    LocalStorageService.destroyAccessToken();
    LocalStorageService.destroyRefreshToken();
    LocalStorageService.destroyResendCodeTimer();
    LocalStorageService.destroyAuthVerified();
    context.commit('usersStore/DESTROY_USER');
    this.$api.setHeader();
    router.push({name: 'login'});
  },
  [IS_AUTH]() {
    return this.$api.get('api/auth/is_auth').then((res) => {
      return res.data.data;
    }).catch((error) => {
      if (error.response.status === 401) {
        console.log('not authorized');
      }
    });
  },
  [CHECK_RESET_TOKEN](context, token) {
    return this.$api.get(`api/auth/reset_password?token=${token}`);
  },
  [RESET_PASSWORD](context, dataToSubmit) {
    return this.$api.post('api/auth/forgot_password', dataToSubmit);
  },
  [CHANGE_PASSWORD](context, {token, payload}) {
    return this.$api.post(`api/auth/reset_password?token=${token}`, payload);
  },
  [TERMS_ACTIVATE](context, {accessId, token, payload}) {
    return this.$api.post(`api/auth/activate?access_id=${accessId}&token=${token}`, payload);
  },
};

export default {
  state,
  getters,
  mutations,
  actions
};
