const loyalty = [
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/finances/TransactionsView'),
    meta: {
      title: 'finances'
    },
  },
    {
        path: '/transactionsApartment',
        name: 'transactionsApartment',
        component: () => import('@/views/finances/TransactionsApartment'),
        meta: {
            title: 'transactions'
        },
    },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('@/views/finances/PaymentsList'),
    meta: {
      title: 'payments'
    },
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/finances/InvoicesList'),
    meta: {
      title: 'invoices'
    },
  },
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import('@/views/finances/AnalyticsView'),
    meta: {
      title: 'analytics'
    },
  },
  // {
  //   path: '/documents',
  //   name: 'documentsList',
  //   component: () => import('@/views/documents/DocumentsList'),
  //   meta: {
  //     title: 'my_documents'
  //   },
  // },
];

export default loyalty;
