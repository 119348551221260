import notify from "@/core/services/notify.service";

export default {
  namespaced: true,
  state: {
    internetTvTotal: null,
    packages: [],
  },

  getters: {
    INTERNET_TV_TOTAL(state) {
      return state.internetTvTotal;
    },
    PACKAGES(state) {
      return state.packages;
    },
  },

  mutations: {
    SET_PACKAGES(state, data) {
      state.packages = data;
    },
    CLEAR_DATA(state) {
      state.internetTvTotal = null;
    }
  },

  actions: {
    GET_FILE(context, {apartmentID, filters, type}) {
      let routeName;

      switch (type) {
        case 'pdf':
          routeName = 'filePdf';
          break;
        default:
          routeName = 'file';
          break;
      }

      return this.$api.get(`api/apartments/internetTv/${apartmentID}/${routeName}`, {params: filters})
        .then((response) => {
          if(response.data.data) {
            window.open(response.data);
            return;
          }
          notify.success(response.data.message);
        });
    },
    GET_INTERNET_TV_TOTAL(context) {
      if (!context.state.electricityTotal) {
        return this.$api.get('api/apartments/internetTv/total', null, false).then((response) => {
          context.state.electricityTotal = response.data.data;
        });
      }
    },
    GET_PACKAGES(context) {
      return this.$api.get('api/apartments/internetTv/tariffs').then(response => {
        context.commit('SET_PACKAGES', response.data.data);
      })
    },
    ORDER_PACKAGE(context, {apartmentId, internetID, tvID}) {
      return this.$api.post(`api/apartments/internetTv/order/${apartmentId}`, {internetID: internetID, tvID: tvID}, true);
    },
    CANCEL_PACKAGE(context, apartmentId) {
      return this.$api.patch(`api/apartments/internetTv/cancel/${apartmentId}`, null, true);
    },
    TOGGLE_SUBSCRIPTION(context, { apartmentId, status }) {
      return this.$api.patch(`api/apartments/internetTv/pause/${apartmentId}`, {
        status: status
      }, true);
    }
  }
};
