const devices = [
  {
    path: '/devices',
    name: 'devicesList',
    component: () => import('@/views/devices/DevicesList.vue'),
    meta: {
      title: 'devices'
    },
  }
];

export default devices;
