export default {
  namespaced: true,
  state: {
    departments: [],
    list: null,
    messages: null,
    unreadMessagesCount: 0,
    file: null
  },

  getters: {
    DEPARTMENTS(state) {
      return state.departments;
    },
    LIST(state) {
      return state.list;
    },
    MESSAGES(state) {
      return state.messages;
    },
    UNREAD_MESSAGES_COUNT(state) {
      return state.unreadMessagesCount;
    },
    FILE(state) {
      return state.file;
    },
  },

  mutations: {
    SET_UNREAD_MESSAGES_COUNT(state, count) {
      state.unreadMessagesCount = count;
    },
    SET_FILE(state, data) {
      state.file = data;
    },
    CLEAR_DATA(state) {
      state.list = null;
      state.messages = null;
    },
    CLEAR_MESSAGES(state) {
      state.messages = null;
    },
  },

  actions: {
    GET_DEPARTMENTS(context) {
      if (!context.state.departments.length) {
        return this.$api.get(`api/tickets/departments`).then((response) => {
          context.state.departments = response.data.data;
        });
      }
    },
    GET_LIST(context) {
      return this.$api.get(`api/tickets/list`, null, false).then((response) => {
        context.state.list = response.data.data;
      });
    },
    GET_MESSAGES(context, ticketID) {
      return this.$api.get(`api/tickets/${ticketID}/messages`, null, false).then((response) => {
        context.state.messages = response.data.data;
      });
    },
    GET_FILE(context, fileId) {
      return this.$api.get(`api/tickets/file/${fileId}`, {responseType: 'blob'}, false);
    },
    SEND_MESSAGE(context, {ticketID, dataToSubmit}) {
      return this.$api.post(`api/tickets/${ticketID}/messages`, dataToSubmit, false, false);
    },
    CREATE(context, dataToSubmit) {
      return this.$api.post(`api/tickets`, dataToSubmit).then((response) => {
        return response.data.data.ticketId;
      });
    },
    CLOSE(context, ticketID) {
      return this.$api.patch(`api/tickets/${ticketID}`).then((response) => {
      });
    },
  }
};
