const dashboard = [
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Dashboard.vue'),
    meta: {
      title: 'dashboard'
    },
  },
];

export default dashboard;
