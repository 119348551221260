export default {
  namespaced: true,
  state: {
    services: []
  },

  getters: {
    SERVICES(state) {
      return state.services;
    }
  },

  mutations: {
    SET_SERVICES(state, services) {
      state.services = services;
    }
  },

  actions: {
    GET_SERVICES(context, bookingId) {
      return this.$api.get(`api/services/${bookingId}`).then((response) => {
        context.commit('SET_SERVICES', response.data.data);
      });
    },
    ORDER(context, {services, bookingId}) {
      return this.$api.post(`api/services/${bookingId}`, {services: services});
    }
  }
};
