const loyalty = [
  {
    path: '/loyalty',
    name: 'loyalty',
    component: () => import('@/views/loyalty/Show'),
    meta: {
      title: 'loyalty'
    },
  },
  {
    path: '/loyalty/new-recommendation',
    name: 'addRecommendation',
    component: () => import('@/views/loyalty/NewRecommendationView'),
    meta: {
      title: 'new_recommendation'
    },
  },
];

export default loyalty;
