export default {
  namespaced: true,
  state: {
    countries: null,
    history: null,
    wallet: {},
    products: null,
    recommendations: []
  },

  getters: {
    COUNTRIES(state) {
      return state.countries;
    },
    HISTORY(state) {
      return state.history;
    },
    WALLET(state) {
      return state.wallet;
    },
    PRODUCTS(state) {
      return state.products;
    },
    RECOMMENDATIONS(state) {
      return state.recommendations;
    },
  },

  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = countries.map(el => {
        return {
          id: el.alpha2Code,
          label: el.name,
        }
      });
    },
    SET_WALLET(state, wallet) {
      state.wallet = wallet;
    },
    CLEAR_DATA(state) {
      state.history = null;
      state.wallet = null;
      state.products = null;
      state.recommendations = null;
    }
  },

  actions: {
    GET_WALLET(context) {
      if (!Object.values(context.state.wallet).length) {
        return this.$api.get('api/loyalty/wallet', null, false).then((response) => {
          context.commit('SET_WALLET', response.data.data);
        });
      }
    },
    GET_HISTORY(context) {
      if (!context.state.history) {
        return this.$api.get('api/loyalty/history').then((response) => {
          context.state.history = response.data.data;
        });
      }
    },
    GET_PRODUCTS(context) {
      if (!context.state.products) {
        return this.$api.get('api/loyalty/products').then((response) => {
          context.state.products = response.data.data;
        });
      }
    },
    GET_RECOMMENDATIONS(context) {
      return this.$api.get('api/loyalty/recommendations', null, false).then((response) => {
        context.state.recommendations = response.data.data;
      });
    },
    GET_COUNTRIES(context) {
      return this.$api.get('https://restcountries.eu/rest/v2/all/', {
        transformRequest: (data, headers) => {
          delete headers.common['access-token'];
          delete headers.common['refresh-token'];
          delete headers.common['user-language'];
        }
      }).then((response) => {
        context.commit('SET_COUNTRIES', response.data);
      });
    },
    CREATE_RECOMMENDATION(context, dataToSubmit) {
      return this.$api.post('api/loyalty/recommendations', dataToSubmit);
    },
    WRITE_OFF_POINTS(context, productId) {
      return this.$api.post(`api/loyalty/exchange_points/${productId}`, null, true);
    }
  }
};
