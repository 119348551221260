const services = [
  {
    path: '/services',
    name: 'servicesList',
    component: () => import('@/views/services/List'),
    meta: {
      title: 'services'
    }
  }
];

export default services;
