export default {
  namespaced: true,
  state: {},

  getters: {},

  mutations: {},

  actions: {
    DOWNLOAD_FILE(context, fileId) {
      return this.$api.get(`api/transactions/docs/${fileId}`).then((response) => {
        window.open(response.data);
      });
    }
  }
};
