export default {
  namespaced: true,
  state: {
    apartment: null,
    billingList: [],
    list: null,
  },

  getters: {
    APARTMENT(state) {
      return state.apartment;
    },
    BILLING_LIST(state) {
      return state.billingList;
    },
    LIST(state) {
      return state.list;
    },
  },

  mutations: {
    CLEAR_BILLING_LIST(state) {
      state.billingList = [];
    },
    CLEAR_DATA(state) {
      state.apartment = null;
      state.billingList = [];
      state.list = null;
    }
  },

  actions: {
    GET_LIST(context) {
      return this.$api.get(`api/apartments/list`).then((response) => {
        context.state.list = response.data.data;
      });
    },

    GET_BILLING_LIST(context) {
      if (!context.state.billingList.length) {
        return this.$api.get(`api/apartments/billing`, null, false).then((response) => {
          context.state.billingList = response.data.data;
        });
      }
    },

    GET_DETAILS(context, apartmentID) {
      return this.$api.get(`api/apartments/${apartmentID}`).then((response) => {
        context.state.apartment = response.data.data;
      });
    },

    PAY(context, {epCode, type, serviceId, sum}) {
      this.$api.post(`api/pay/${type}/${epCode}`, {serviceId: serviceId, sum: sum}, false)
        .then((response) => {
          window.open(response.data.data, '_blank').focus();
        });
    }
  }
};
