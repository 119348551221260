export default {
  namespaced: true,
  state: {
    events: [],
  },

  getters: {
    EVENTS(state) {
      return state.events;
    }
  },

  mutations: {
    SET_EVENTS(state, events) {
      state.events = events && events.length
        ? events.map(el => {
          let className;
          switch (el.status) {
            case 'new':
              className = 'bg-warning-o-50';
              break;
            case 'approved':
              className = 'bg-success-o-50';
              break;
            case 'canceled':
              className = 'bg-danger-o-50';
              break;
          }
          return {
            ...el,
            content: /*el.status === 'new' ? '<span class="fas fa-circle text-danger calendar-event-dot"></span>' : */null,
            class: 'text-dark position-relative cursor-pointer ' + className
          }
        })
        : [];
    },
  },

  actions: {
    GET_EVENTS(context, {requestUrl, filters, date_range}) {
      return this.$api.get(requestUrl, {params: {date_range, filters}}).then((response) => {
        context.commit('SET_EVENTS', response.data.data);
      });
    },
  }
};
