<template>
  <!-- begin::Page loader -->
  <div class="d-flex justify-content-center page-loader page-loader-logo">
    <div class="d-flex align-items-center page-loader__wrapper bg-white px-5 py-3">
      <div class="spinner-border ml-auto text-primary mr-3" role="status" aria-hidden="true"></div>
      <span>{{ $t('description.please_wait') }}...</span>
    </div>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: "Loader",
  props: {
    logo: String,
    spinnerClass: String
  }
};
</script>

<style lang="scss" scoped>
.page-loader::v-deep {
  background: rgba(0, 0, 0, 0.5);

  & .page-loader__wrapper {
    border-radius: 4px;
  }
}
</style>
