export default {
  namespaced: true,
  state: {
    servicesTotal: null,
  },
  getters: {
    SERVICES_TOTAL(state) {
      return state.servicesTotal;
    },
  },
  mutations: {
    CLEAR_DATA(state) {
      state.servicesTotal = null;
    }
  },
  actions: {
    GET_SERVICES_TOTAL(context) {
      // if (!context.state.servicesTotal) {
        return this.$api.get('api/apartments/services/total', null, false).then((response) => {
          context.state.servicesTotal = response.data.data;
        });
      // }
    },
    GET_FILE(context, {apartmentID, filters, type}) {
      let routeName;

      switch (type) {
        case 'pdf':
          routeName = 'filePdf';
          break;
        default:
          routeName = 'file';
          break;
      }

      return this.$api.get(`api/apartments/services/${apartmentID}/${routeName}`, {params: filters})
        .then((response) => {
          window.open(response.data);
        });
    },
  }
};
