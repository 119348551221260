import Vue from 'vue'
import Vuex from 'vuex'
import plugins from '@/plugins/vuex'

import auth from '@/store/core/auth.module';
import config from '@/store/core/config.module';
import htmlClass from '@/store/core/htmlclass.module';
import loader from '@/store/core/loader-store';
import subheader from '@/store/core/subheader-store';

import notifyStore from '@/store/core/notify-store';
import calendarStore from '@/store/calendar/calendar-store';
import tableStore from '@/store/table/table-store';

import analyticsStore from '@/store/analytics/analytics-store';
import apartmentsStore from '@/store/apartments/apartments-store';
import apartmentElectricityStore from '@/store/ap-services/apartment-electricity-store';
import apartmentServicesStore from '@/store/ap-services/apartment-services-store';
import apartmentWaterStore from '@/store/ap-services/apartment-water-store';
import apartmentInternetTvStore from '@/store/ap-services/apartment-internet-tv-store';
import bookingsStore from '@/store/bookings/bookings-store';
import contractsStore from '@/store/contracts/contracts-store';
import devicesStore from '@/store/devices/devices-store';
import documentsStore from '@/store/documents/documents-store';
import eKeysStore from '@/store/e-keys/electronic-keys-store';
import guestBookingStore from './guest-booking/guest-booking-store';
import helpStore from '@/store/help/help-store';
import loyaltyStore from '@/store/loyalty/loyalty-store';
import notificationsStore from '@/store/notifications/notifications-store';
import servicesStore from '@/store/services/services-store';
import ticketsStore from '@/store/tickets/tickets-store';
import transactionsStore from '@/store/transactions/transactions-store';
import usersStore from '@/store/users/users-store';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [plugins],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    config,
    htmlClass,
    loader,
    subheader,

    notifyStore,
    calendarStore,
    tableStore,

    analyticsStore,
    apartmentsStore,
    apartmentElectricityStore,
    apartmentServicesStore,
    apartmentWaterStore,
    apartmentInternetTvStore,
    bookingsStore,
    contractsStore,
    devicesStore,
    documentsStore,
    eKeysStore,
    guestBookingStore,
    helpStore,
    loyaltyStore,
    notificationsStore,
    servicesStore,
    ticketsStore,
    transactionsStore,
    usersStore,
  }
})
