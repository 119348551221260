export default {
  namespaced: true,
  state: {
    desktopList: [],
    mobileList: []
  },
  getters: {
    DESKTOP_LIST(state) {
      return state.desktopList;
    },
    MOBILE_LIST(state) {
      return state.mobileList;
    }
  },
  mutations: {
    SET_DESKTOP_LIST(state, orders) {
      state.desktopList = orders;
    },
    SET_MOBILE_LIST(state, orders) {
      state.mobileList = orders;
    },
    CLEAR_DATA(state) {
      state.desktopList = [];
      state.mobileList = [];
    },
  },
  actions: {
    GET_LIST(context) {
      return this.$api.get('api/users/devices').then(response => {
        const data = response?.data?.data;
        if (!data) return;
        context.commit('SET_DESKTOP_LIST', data?.desktops);
        context.commit('SET_MOBILE_LIST', data?.mobiles);
      });
    },
    DELETE_DEVICE(context, deviceId) {
      return this.$api.delete(`api/users/device/${deviceId}`);
    }
  }
};
