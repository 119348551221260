export default {
  namespaced: true,
  state: {
    totalRows: null,
  },

  getters: {
    TOTAL_ROWS(state) {
      return state.totalRows;
    }
  },

  mutations: {
    SET_TOTAL_ROWS(state, totalRows) {
      state.totalRows = totalRows;
    },
  },

  actions: {
    GET_COLUMNS(context, moduleName) {
      return this.$api.get(`api/config/columns/${moduleName}`, null, false).then((response) => {
        return response.data.data;
      });
    },

    GET_FILTERS(context, moduleName) {
      return this.$api.get(`api/config/filters/${moduleName}`, null, false).then((response) => {
        return response.data.data;
      });
    },

    GET_ITEMS(context, {requestUrl, filters, pagination}) {
      return this.$api.get(requestUrl, {params: {pagination, filters}}, false).then((response) => {
        context.commit('SET_TOTAL_ROWS', response.data.data.meta?.items_count);
        return response.data.data;
      });
    },
  }
};
