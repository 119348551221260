export default {
  btn: {
    accept: 'მიღება',
    add_recommendation: 'რეკომენდაციის დამატება',
    back: 'უკან',
    back_to_loyalty: 'საბონუსე სისტემაში დაბრუნება',
    book: 'დაჯავშნა',
    cancel: 'გაუქმება',
    cancel_booking: 'ჯავშნის გაუქმება',
    change_password: 'პაროლის შეცვლა',
    clear: 'გასუფთავება',
    click_to_download: 'გადმოწერა',
    confirm: 'დადასტურება',
    continue_to_checkout: 'განაგრძეთ გადახდა',
    copy_to_clipboard: 'კოპირება ბუფერში',
    create: 'შექმნა',
    create_ticket: 'დახმარების მოთხოვნა',
    decline: 'უარყოფა',
    delete: 'წაშლა',
    download: 'ჩამოტვირთვა',
    monthly_report: 'ყოველთვიური რეპორტი',
    daily_report: 'ყოველდღიური რეპორტი',
    export_history: 'ისტორიის ექპორტი',
    generate: 'გენერირება',
    generate_key: 'გასაღების გენერირება',
    get_back_home: 'Get back home',
    key_generation: 'გასღების გენერაცია',
    next: 'შემდეგი',
    no: 'არა',
    ok: 'კი',
    order: 'შეკვეთა',
    order_service: 'სერვისების შეკვეთა',
    pause: 'დაპაუზება',
    pay: 'გადახდა',
    resend_code: 'Resend code',
    search: 'ძიება',
    send: 'გაგზავნა',
    show_orders: 'შეკვეთების ჩვენება',
    show_unpaid_invoices: 'გადაუხდელი ინვოისების ჩვენება',
    sign_in: 'შესვლა',
    sign_out: 'გასვლა',
    sign_up: 'რეგისტრაცია',
    start: 'თავიდან',
    submit: 'გაგზავნა',
    support: 'მხარდაჭერა',
    reject: 'უარყოფა',
    reset_password: 'პაროლის აღდგენა',
    understand: 'გასაგებია',
    resume: 'გაგრძელება',
    update: 'განახლება',
    write_off: 'Write off',
    write_off_points: 'Points write off application',
    yes: 'დიახ',
    change_package: 'პაკეტის ცვლილება',
  },

  description: {
    already_qr_booked: 'აღნიშნული მისამართი არის არავალიდური ან უკვე იქნა გამოყენებული - ვიზიტის დასაჯავშნად - თქვენს მიერ.',
    coming_soon: 'Coming soon',
    check_email_for_GB_instructions: 'გთხოვთ, შეამოწმოთ თქვენი ელეტრონული ფოსტა.',
    enter_email_to_start_reservation: 'დაჯავშნის პროცესის დასაწყებად გთხოვთ, შეიყვანოთ თქვენი მეილი. მეილზე მიიღებთ ლინკს დაჯავშნის პროცესის დასასრულებლად.',
    enter_personal_details_for_gb: 'გთხოვთ, თქვენი მონაცემები შეიყვანოთ ისე როგორც მითითებულია პასპორტში.',
    list_is_empty: 'სია ცარიელია',
    new_password_rules: 'პაროლი უნდა შედგებოდეს მინიმუმ 8 სიმბოლოსგან და შეიცავდეს მინიმუ ერთ დიდ ლათინურ ასოს და ერთ ციფრს',
    no_apartments: 'თქვენ არ გაქვთ აპარტამენტები',
    no_data: 'მონაცემები არ არის',
    no_status: 'სტატუსი უცნობია',
    password_changed: 'პაროლი წარმატებით შეიცვალა',
    password_recovery_instructions_sent: 'პაროლის აღდგენის ინსტრუქცია გამოგეგზავნათ ელ. ფოსტაზე',
    please_wait: 'გთხოვთ მოიცადოთ',
    reset_password_in_two_steps: 'აღადგინე პაროლი ორი სწრაფი ნაბიჯით',
    text_copied: 'ტექსტი დაკოპირებულია',
    guest_booking_time_is_ticking: '<b>ჩართულია დროის აღრიცხვა!</b> თქვენ გაქვთ 10 წუთი რათა დაასრულოთ დაჯავშნის პროცესი. თუ დრო ამოიწურება, თქვენი ჯავშანი გაუქმდება.',
    guest_booking_time_is_up: 'სამწუხაროდ 10 წუთიანი პერიოდი ამოიწურა და თქვენ ვეღად დაასრულებთ დაჯავშნას. არ ინერვიულოთ. თქვენ შეგიძლიათ დაჯავშნის პროცესი დაიწყოთ თავიდან.',
    someone_booked_dates_you_selected: 'მთითებულ პერიოდში დაჯავშნა ვერ მოხერხდა. გთხოვთ, აირჩიოთ ალტერნატიული პერიოდი.',
    gb_request_created: 'თქვენი მოთხოვნა მიღებულია',
    gb_request_created_desc: 'სრული ინფორმაცია ჯავშნის შესახებ გამოგეგზნათ მეილზე. გთხოვთ შეამოწმოთ ელეტრონული ფოსტა.',
  },

  error: {
    children_age: 'ბავშვის მაქსიმალური ასაკი 12 წელია',
    children_count: 'არ ემთხვევა ბავშვების რაოდენობას',
    field_is_required: 'ველი აუცილებელია',
    invalid_age: 'შეყვანილია არასწორი ასაკი',
    invalid_email: 'Არასწორი ელ. ფოსტა',
    invalid_number: 'არასწორი ნომერი',
    min_length_is: 'მინიმალური სიმბოლოების სიგრძეა',
    password_security_failed: 'პაროლი არ აკმაყოფილებს უსაფრთხოების მინიმალურ მოთხოვნებს',
    passwords_not_the_same: 'პაროლები უნდა იყოს ერთნაირი',
    reset_token_expired: 'პაროლის აღდგენის ამ სესიას ვადა გაუვიდა. გთხოვთ, დააჭიროთ „პაროლის აღდგენას“ და დაიწყოთ თავიდან.',
    something_goes_wrong: 'ელ.ფოსტა არასწორია. სცადეთ თავიდან ან დაგვიკავშირდით',
    wrong_credentials: 'მომხმარებლის სახელი ან პაროლი არასწორია',
  },

  help: {
    cant_delete_current_session: "მიმდინარე სესიის წაშლა არ შეგიძლიათ",
    choose_apartment_and_income_date_first: 'ჯერ აირჩიეთ აპარტამენტი და ჩამოსვლის თარიღი',
    choose_apartment_first: 'ჯერ აირჩიეთ აპარტამენტი',
    ekey_desc_card: 'You are going to send electronic key to guest',
    ekey_desc_ekey: 'თქვენ აპირებთ ციფრული გასაღების გაგზავნას თქვენი სტუმრისთვის, ' +
        'რომლითაც ის შეძლებს აპარტამენტის კარის გაღებას ვიზიტის პერიოდში. ' +
        'ციფრული გასაღები გაიგზავნება თქვენი სტუმრის ელფოსტაზე.',
    enter_guest_fields: 'შეავსეთ ქვემოთ მოცემული ველები მხოლოდ იმ შემთხვევაში, თუ ჯავშნით აპარტამენტს სხვისთვის',
    how_to_get_points: 'როგორ მივიღოთ ქულები',
    min_length_is: 'სიმბოლოების მინიმალური სიგრძე:',
    redirect_to_payment_page: "You will be redirected to payment page after 3 seconds",
    unconfirmed_points: 'Point(s) needs to be confirmed',
    who_will_get_keys: 'ვინ მიიღებს გასაღებს?',
    we_never_share_your_email: 'We\'ll never share your email with anyone else',
    we_never_share_your_phone_number: 'We\'ll never share your phone number with anyone else',
    we_sent_2auth_code_to_email: 'We have sent a confirmation code to your email. Please enter it'
  },

  label: {
    accepted: 'მიღებულია',
    active_keys: 'აქტიური გასაღებები',
    address: 'მისამართი',
    adult_age: 'ასაკი 13-დან',
    adults_plural: '{count} ზრდასრული | {count} ზრდასრული',
    adults: 'ზრდასრულები',
    age_needed: 'ასაკი სავალდებულოა',
    all: 'ყველა',
    apartment: 'აპარტამენტი',
    apartment_crm_id: 'Apartment CRM ID',
    apartments: 'აპარტამენტები',
    area: 'Area',
    authorization: 'ავტორიზაცია',
    available_points: 'Available point(s)',
    balance: 'ბალანსი',
    block: 'ბლოკი',
    booking: 'დაჯავშნა',
    booking_accepted: 'დაჯავშნა მიღებულია',
    booking_id: 'დაჯავშნის ID',
    bookings: 'ჯავშნები',
    bookings_count: 'ჯავშნები',
    bookings_new: 'ახალი',
    bookings_current: 'მიმდინარე',
    bought: 'Bought',
    cadastre: 'საკადასტრო ნომერი',
    calendar: 'კალენდარი',
    card: 'Card',
    change_password: 'პაროლის შეცვლა',
    check_in: 'შემოსვლა',
    check_out: 'გასვლა',
    child_age: 'ასაკი 0-დან 12 წლამდე',
    children: 'ბავშვები',
    children_age: 'ბავშვების ასაკი',
    children_plural: '{count} ბავშვი | {count} ბავშვი',
    citizenship: 'მოქალაქეობა',
    city: 'ქალაქი',
    click_to_toggle: 'დეტალურად',
    close_ticket: 'დახმარების დასრულება',
    closed_ticket: 'ბილეთი დახურულია',
    comment: 'კომენტარი',
    confirm_booking: 'დაადასტურეთ დაჯავშნა',
    confirm_password: 'გაიმეორეთ პაროლი',
    confirm_trusted_device: 'დაადასტურეთ სანდო მოწყობილობა',
    confirmed: 'დადასტურებულია',
    confirmed_points: 'დადასტურებული ქულები',
    contact: 'კონტაქტი',
    copied: 'კოპირებულია',
    count: 'რაოდენობა',
    counter_id: 'მრიცხველის ID',
    counter_readings: 'მრიცხველის მაჩვენებელი',
    country: 'ქვეყანა',
    current_password: 'მიმდინარე პაროლი',
    date: 'თარიღი',
    debt: 'დავალიანება',
    departure: 'Departure',
    desktop: 'Desktop',
    device: 'მოწყობილობა',
    dont_generate: 'Don\'t generate',
    dont_have_account: 'ჯერ არ გაქვს ანგარიში',
    door_openings: 'Doors openings',
    doors_history: 'კარების ისტორია',
    download_data: 'ჩამოტვირთეთ მონაცემები',
    ekey: 'Electronic key',
    electricity: 'ელ. ენერგია',
    first_login: 'პირველი შესვლა',
    internet_tv: 'ინტერნეტი და ტელევიზია',
    email: 'ელ.ფოსტა',
    email_address: 'Email address',
    entry: 'Entry',
    exit_date: 'გამგზავრების თარიღი',
    female: 'მდედრობითი',
    floor: 'სართული',
    forgot_password: 'დაგავიწყდათ პაროლი',
    full_name: 'სრული სახელი',
    gender: 'სქესი',
    generate_key: 'Do you want to generate & send key?',
    guests: 'სტუმრები',
    have_account: 'გაქვთ ანგარიში',
    hello: 'გამარჯობა',
    history: 'ისტორია',
    id: 'ID',
    in_process: 'პროცესშია',
    income_date: 'ჩამოსვლის თარიღი',
    invoice: 'ინვოისი',
    guest: 'სტუმარი',
    last_login: 'ბოლო შესვლა',
    last_name: 'გვარი',
    last_opened_at: 'ბოლოს გაიხსნა',
    last_update: 'ბოლო განახლება',
    leave_something_for_host: 'შეგიძლიათ მიუთითოთ',
    log: 'Log',
    male: 'მამრობითი',
    manual: 'სახელმძღვანელო',
    me: 'მე',
    messenger: 'Messenger-ი',
    month: 'თვე',
    my_recommendations: 'ჩემი რეკომენდაციები',
    name: 'სახელი',
    next_payment: 'შემდეგი გადახდა',
    new_password: 'ახალი პაროლი',
    nights_count: 'ღამეების რაოდენობა',
    number: 'ნომერი',
    object: 'ობიექტი',
    online: 'ონლაინ',
    oops: 'ყურადღება',
    orbi_group: 'ორბი ჯგუფი',
    orders: 'შეკვეთები',
    orders_total: 'შეკვეთები სულ',
    os_system: 'ოპერაციული სისტემა',
    owner: 'მფლობელი',
    book_for_myself: 'დაჯავშვნა ჩემთვის',
    book_for_guest: 'დაჯავშნა სტუმრისთვის',
    password: 'პაროლი',
    password_recovery: 'პაროლის აღდგენა',
    passport_number: 'პასპორტის ნომერი',
    paused_keys: 'შეჩერებული გასაღებები',
    payment_type: 'გადახდის ტიპი',
    person: 'პერსონა',
    personal_id: 'პირადი ID',
    personal_number: 'პასპორტის ნომერი',
    phone_number: 'ტელეფონის ნომერი',
    point: 'ქულა',
    point_s: 'ქულები',
    points: 'ქულა',
    price: 'ფასი',
    sum: 'თანხა',
    products: 'პროდუქტები',
    profile: 'პროფილი',
    refused: 'უარი თქვა',
    recommendations: 'რეკომენდაციები',
    recommendations_statistics: 'რეკომენდაციების სტატისტიკა',
    remains_to_pay: 'რჩება გადასახდელი',
    revenue: 'შემოსავალი',
    second_name: 'მეორე სახელი',
    services: 'სერვისები',
    apartment_service: 'მომსახურება',
    security_policy: 'უსაფრთხოების პოლიტიკა',
    payment_details: 'გადახდის დეტალები',
    sign_in: 'შესვლა',
    sign_up: 'რეგისტრაცია',
    smartphone: 'სმარტფონი',
    spent_points: 'დახარჯული ქულები',
    square: 'კვადრატი',
    status: 'სტატუსი',
    table: 'ცხრილი',
    terms_and_conditions: 'წესები და პირობები',
    today_readings: 'დღევანდელი მაჩვენებელი',
    total: 'სულ',
    type: 'წყარო',
    unconfirmed_points: 'Unconfirmed points',
    until_full_repayment: 'Until full repayment',
    username: 'მომხმარებლის სახელი',
    utilities: 'კომუნალური',
    vat: 'დღგ',
    visa: 'Visa',
    visa_mastercard: 'Visa / Mastercard',
    water: 'წყალი',
    year: 'წელიწადი',
    years_old: 'წლის',
    you_earned_point: 'You earned point',
    you_have: 'შენ გაქვს',
    your_personal_number: 'შენი პირადი ნომერი',
    your_wishes: 'თქვენი სურვილი',
    open: 'ღია',
    closed: 'დახურული',
    passport_scan: 'პასპორტის ასლი',
    ep_code: 'epCode',
    monthly_tariff: 'ყოველთვიური ტარიფი',
    day: 'დღე',
    start_date: 'მომსახურების დაწყების თარიღი',
    water_code: 'წყლის კოდი',
    active: 'აქტიური',
    passive: 'პასიური',
    paused: 'დაპაუზებული',
    package_change_date: 'პაკეტის შეცვლის თარიღი',
    cost: 'ხარჯი',
    discount: 'ფასდაკლება',
    disabled_date: 'გათიშვის თარიღი',
    internet: 'ინტერნეტი',
    tv: 'ტელევიზია',
    choose_language: 'Choose language',
    when_is_trip: 'ჯავშნის პერიოდი',
    who_is_coming: 'ინფორმაცია ვიზიტორის შესახებ',
    who_is_your_guest: 'ინფორმაცია ვიზიტორის შესახებ'
  },

  lang: {
    en: "English",
    ge: "ქართული",
    ru: "Русский"
  },

  link: {},

  modal: {
    accept_booking_question: 'დარწმუნებული ხართ, რომ გსურთ ჯავშნის მიღება?',
    accept_booking_parallel_bookings: 'დადასტურება ასევე გამოიწვევს სხვა ჯავშენების გაუქმებას:',
    accept_booking_with_key_title: 'თქვენ ახლა იღებთ ჯავშანს და აპირებთ ციფრული გასაღების გაგზავნას თქვენი სტუმრისთვის, ' +
        'რომლითაც ის შეძლებს აპარტამენტის კარის გაღებას ვიზიტის პერიოდში.',
    accept_booking_with_key_question: 'დარწმუნებული ხართ, რომ გსურთ მიიღოთ ჯავშანი და შექმნათ ციფრული გასაღები აპარტამენტისთვის?',
    accept_booking_without_key_title: 'თქვენ ახლა იღებთ ჯავშანს აპარტამენტის ელექტრო გასაღების გაგზავნის გარეშე',
    are_you_sure: 'Are you sure',
    booking_accepted: 'თქვენმა სტუმარმა მიიღო ჯავშნის ყველა დეტალი და ციფრული გასაღები ელფოსტით',
    booking_accepted_body: 'ჯავშანი წარმატებით იქნა მიღებული',
    booking_created_body: 'ჯავშანი წარმატებით შეიქმნა',
    booking_created_title: 'ჯავშანი შექმნილია',
    booking_created_for_guest_desc_1: 'თქვენმა სტუმარმა მიიღო ელფოსტა ჯავშნის შესახებ',
    booking_created_for_guest_desc_2: 'გთხოვთ, დაეთანხმოთ ამ ჯავშანს 48 საათში, წინააღმდეგ შემთხვევაში ის ავტომატურად უარყოფილი იქნება',
    booking_created_for_guest_desc_3: 'თქვენ შეგიძლიათ დაჯავშნის მიღებისას თქვენს სტუმარს მისცეთ ციფრული გასაღები',
    booking_declined: 'ჯავშანი წარმატებით უარყოფილია',
    booking_declined_body: 'ჯავშანი წარმატებით იქნა უარყოფილი',
    confirm_device_delete: 'ყურადღება! გსურთს თქვენი მოწყობილობის წაშლა? წაშლილი მოწყობილობა შეგიძლიათ დაამატოთ ხელახლა. თუ ამ მოწყობილობიდან თავიდან გაივლით ავტორიზაციას და შეიყვანთ სმს კოდს.',
    confirm_internet_pause: 'ყურადღება! გსურთ სერვისის დაპაუზება? ყოველთვიური პაუზის საფასურია 6 ლარი',
    confirm_internet_resume: 'ყურადღება! დარწმუნებული ხართ რომ გინდათ გაგრძელება?',
    confirm_package_order: 'Do you really want to change package?',
    decline_booking_question: 'დარწმუნებული ხართ, რომ გსურთ გააუქმოთ ჯავშანი?',
    key_generated: 'ციფრული გასაღები წარმატებით გენერირებულია',
    key_generated_body: 'ციფრული გასაღები წარმატებით შეიქმნა',
    generate_key_for_guest_title: 'თქვენ აპირებთ ციფრული გასაღების გაგზავნას თქვენი სტუმრისთვის, ' +
          'რომლითაც ის შეძლებს აპარტამენტის კარის გაღებას ვიზიტის პერიოდში.',
    generate_key_for_guest_body: 'ციფრული გასაღები გაიგზავნება თქვენი სტუმრის ელფოსტაზე.',
    generate_key_for_guest_question: 'გსურთ ციფრული გასაღების გაგზავნა?',
    you_can_generate_key: 'თქვენ ასევე შეგიძლიათ შექმნათ ციფრული გასაღები თქვენი სტუმრისთვის, ' +
        'რომლითაც ის შეძლებს აპარტამენტის კარის გაღებას ვიზიტის პერიოდში.',
    generate_key_for_personal_title: 'თქვენ ელ.ფოსტაზე მიიღებთ ელექტორნულ გასაღებს, ' +
        'რომლითაც შეძლებთ აპარტამენტის კარის გაღებას ვიზიტის პერიოდში.',
    generate_key_for_personal_body: 'ციფრული გასაღები გაიგზავნება თქვენს ელ.ფოსტაზე.',
  },

  month: {
    all: 'ყველა',
    january: 'იანვარი',
    february: 'თებერვალი',
    march: 'მარტი',
    april: 'აპრილი',
    may: 'მაისი',
    june: 'ივნისი',
    july: 'ივლისი',
    august: 'აგვისტო',
    september: 'სექტემბერი',
    october: 'ოქტომბერი',
    november: 'ნოემბერი',
    december: 'დეკემბერი',
  },

  navigation: {
    analytics: 'ანალიტიკა',
    apartment_details: 'აპარტამენტის დეტალები',
    apartments: 'აპარტამენტები',
    bonus_program: 'საბონუსე პროგრამა',
    bookings_and_visits: 'დაჯავშნა და ვიზიტები',
    control_panel: 'მართვის პანელი',
    dashboard: 'დეშბორდი',
    deals: 'გარიგებები',
    devices: 'მოწყობილობები',
    your_devices: 'თქვენი მოწყობილობები',
    documents: 'დოკუმენტები',
    finances: 'ფინანსები',
    forgot_password: 'პაროლის აღდგენა',
    help: 'დახმარების ცენტრი',
    loyalty: 'საბონუსე პროგრამა',
    init_guest_booking: 'Start your reservation',
    my_apartments: 'ჩემი აპარტამენტები',
    my_documents: 'ჩემი დოკუმენტები',
    new_recommendation: 'ახალი რეკომენდაცია',
    order: 'შეკვეთა',
    orders: 'შეკვეთები',
    pay: 'გადახდა',
    payments: 'გადახდები',
    profile: 'პროფილი',
    qr_book_cancel: 'Booking Details',
    request_guest_booking: 'Book your apartment',
    reset_password: 'პაროლის აღდგენა',
    services: 'სერვისები',
    sign_in: 'შესვლა',
    sign_up: 'რეგისტრაცია',
    support: 'მხარდაჭერა',
    terms: 'წესები და პირობები',
    transactions: 'ტრანზაქციები',
    verification: 'Two-factor authentication',
    invoices: 'ინვოისები',
    financial_turnover_to_the_contract_of_sale: 'ნასყიდობის ხელშეკრულების ფინანსური ბრუნვა',
    bank_transactions: 'საბანკო ოპერაციები',
    contract_payment_schedule: 'ხელშეკრულების გადახდის გრაფიკი',
    billing_utilities_services: 'ბილინგი, კომუნალურები, სერვისის მომსახურება',
    financial_turnover_of_your_own_apartment: 'საკუთარი აპარტამენტის ფინანსური ბრუნვა',
  },

  notification: {
    new_booking: 'თქვენ გაქვთ დაუდასტურებელი ჯავშანი',
    new_bookings: 'თქვენ გაქვთ დაუდასტურებელი ჯავშნები',
    notifications: 'შეტყობინებები',
    no_new_notifications: 'თქვენ არ გაქვთ შეტყობინებები',
    unread_messages: 'თქვენ გაქვთ წაუკითხავი შეტყობინებები',
  },

  notify: {
    incorrect_token: 'Incorrect token',
    request_already_handled: 'მოთხოვნა უკვე დამუშავდა',
    you_canceled_account_activation: 'თქვენ გააუქმეთ ანგარიშის გააქტიურება',
    your_account_activated: 'თქვენი ანგარიში გააქტიურდა. შეამოწმეთ ელ.ფოსტა',
  },

  pagination: {},

  placeholder: {
    check_in_date: 'შემოსვლის თარიღი',
    check_out_date: 'გასვლის თარიღი',
    choose_apartment: 'აირჩიე აპარტამენტი',
    choose_citizenship: 'აირჩიე მოქალაქეობა',
    choose_country: 'აირჩიეთ ქვეყანა',
    choose_date_and_time: 'აირჩიეთ თარიღი და დრო',
    choose_department: 'აირჩიეთ განყოფილება',
    choose_guest_citizenship: 'აირჩიეთ სტუმრის მოქალაქეობა',
    choose_guest_country: 'აირჩიეთ სტუმარი ქვეყანა',
    choose_guest_gender: 'აირჩიეთ სტუმრის სქესი',
    choose_income_date: 'აირჩიეთ ჩამოსვლის თარიღი',
    choose_exit_date: 'აირჩიეთ გამგზავრების თარიღი',
    choose_language: 'აირჩიეთ ენა',
    choose_payment_type: 'აირჩიეთ გადახდის ტიპი',
    choose_who_will_get_keys: 'აირჩიეთ ვინ მიიღებს გასაღებს',
    from: 'დან',
    enter_child_age: 'Enter child age',
    enter_children_age: 'შეიყვანეთ ბავშვების ასაკი',
    enter_city: 'შეიყვანეთ ქალაქი',
    enter_comment: 'შეიყვანეთ კომენტარი',
    enter_country: 'შეიყვანეთ ქვეყანა',
    enter_current_password: 'შეიყვანეთ ახლანდელი პაროლი',
    enter_email: 'შეიყვანეთ ელ.ფოსტა',
    enter_guest_address: 'შეიყვანეთ სტუმრის მისამართი',
    enter_guest_email: 'შეიყვანეთ სტუმრის ელ.ფოსტა',
    enter_guest_name: 'შეიყვანეთ სტუმრის სახელი',
    enter_guest_last_name: 'შეიყვანეთ სტუმრის გვარი',
    enter_guest_personal_id: 'შეიყვანეთ სტუმრის პასპორტის ნომერი',
    enter_guest_phone_number: 'შეიყვანეთ სტუმრის ტელეფონის ნომერი',
    enter_last_name: 'შეიყვანეთ გვარი',
    enter_messenger: 'შეიყვანეთ messenger-ი',
    enter_name: 'შეიყვანეთ სახელი',
    enter_new_password: 'შეიყვანეთ ახალი პაროლი',
    enter_new_password_one_more_time: 'კიდევ ერთხელ შეიყვანეთ ახალი პაროლი',
    enter_password: 'შეიყვანეთ პაროლი',
    enter_personal_id: 'შეიყვანეთ პირადი ID',
    enter_phone_number: 'შეიყვანეთ ტელეფონის ნომერი',
    enter_second_name: 'შეიყვანეთ მეორე სახელი',
    enter_title: 'შეიყვანეთ სათაური',
    enter_username: 'შეიყვანეთ მომხმარებლი',
    to: 'მდე',
    repeat_new_password: 'გაიმეორეთ ახალი პაროლი',
    type_message: 'ჩაწერეთ შეტყობინება',
    ticket: 'დახმრება',
    upload_file: 'ფაილის ატვირთვა',
    choose_type: 'აირჩიეთ ტიპი',
  },

  table: {
    per_page: 'ჩანაწერების რაოდენობა',
    empty: 'საჩვენებელი ჩანაწერები არ არის'
  },

  title: {
    bonus_program: 'საბონუსე პროგრამა',
    booking_form: 'დაჯავშნის ფორმა',
    book_for_myself: 'დაჯავშვნა ჩემთვის',
    booking_confirmation: 'ჯავშნის დადასტურება',
    booking_details: 'დაჯავშნის დეტალები',
    booking_details_on_the_way: 'ელექტრონულ ფოსტაზე თქვენ გამოგეგზავნათ ინსტრუქცია, რომელიც უნდა გამოიყენეოთ დაჯავშნის პროცესის დასასრულებლად.',
    bookings_and_visits: 'დაჯავშნა და ვიზიტები',
    confirmation: 'Confirmation',
    contracts: 'კონტრაქტები',
    create_new_ticket: 'ახალი დახმარების მოთხოვნა',
    data_view: 'მონაცემების ნახვა',
    electronic_keys: 'ციფრული გასაღებები',
    error: 'შეცდომა',
    every_stay_story: 'აქ ყველა მომენტი ისტორიაა',
    forgot_password: 'პაროლის აღდგენა',
    my_recommendations: 'ჩემი რეკომენდაციები',
    payment_details: 'გადახდის დეტალები',
    services: 'სერვისები',
    apartment_service: 'მომსახურება',
    sign_in: 'შესვლა',
    sign_up: 'რეგისტრაცია',
    success: 'Success',
    user_profile: 'მომხმარებლის პროფილი',
    utilities: 'კომუნალური მომსახურება',
    filters: 'ფილტრები',
  },

  status: {
    booking: {
      approved: 'დადასტურებული',
      canceled: 'გაუქმებული',
      new: 'ახალი',
    },
    points: {
      all: 'ყველა',
      in_process: 'პროცესში',
      new: 'ახალი',
      purchase: 'შეიძინა',
      refuse: 'უარი თქვა',
    }
  },

  ticketTypes: {
    financial_issues: 'ფინანსური საკითხი',
    technical_problem: 'ტექნიკური გაუმართაობა',
    booking_questions: 'ჯავშანთან დაკავშირებული საკითხები',
    digital_key_issue: 'ციფრული გასაღების საკითხები',
    internet_tv_phone: 'ინტერნეტი და ტელევიზია',
    other: 'სხვა ტიპის საკითხები',
  }

};
