import i18n from '../../core/plugins/vue-i18n';

export default {
  namespaced: true,
  state: {
    events: [],
    history: null,
    keysCount: null
  },

  getters: {
    EVENTS(state) {
      return state.events;
    },
    HISTORY(state) {
      return state.history;
    },
    KEYS_COUNT(state) {
      return state.keysCount;
    },
  },

  mutations: {
    SET_EVENTS(state, events) {
      state.events = events && events.length
        ? events.map(el => {
          let title = `<span class="font-weight-bolder">${i18n.t('label.door_openings')}: ${el.title}</span>`
          return {
            ...el,
            class: 'bg-warning-o-75 text-dark position-relative cursor-pointer',
            title: title
          }
        })
        : [];
    },
    CLEAR_HISTORY(state) {
      state.history = null;
    },
    CLEAR_DATA(state) {
      state.events = [];
      state.history = null;
      state.keysCount = null;
    }
  },

  actions: {
    GET_KEYS_COUNT(context) {
      if (!context.state.keysCount) {
        return this.$api.get('api/keys/count', null, false).then((response) => {
          context.state.keysCount = response.data.data;
        });
      }
    },
    GET_EVENTS(context, apartmentID) {
      // if (!context.state.keysCount) {
      return this.$api.get(`api/keys/${apartmentID}/calendar`).then((response) => {
        // context.state.events = response.data.data;
        context.commit('SET_EVENTS', response.data.data);
      });
      // }
    },
    GET_HISTORY(context, {apartmentID, date}) {
      return this.$api.get(`api/keys/${apartmentID}/list?date=${date}`).then((response) => {
        context.state.history = response.data.data;
      });
    },
    EXPORT_HISTORY(context, {apartmentID, filters}) {
      return this.$api.get(`api/keys/${apartmentID}/filePdf`, {params: filters}).then((response) => {
        window.open(response.data);
      });
    },
  }
};
