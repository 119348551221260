const bookings = [
  {
    path: '/bookings',
    name: 'bookingsList',
    component: () => import('@/views/bookings/BookingsView'),
    meta: {
      title: 'bookings_and_visits'
    },
  },
  {
    path: '/bookings/:bookingId/orders',
    name: 'bookingOrders',
    component: () => import('@/views/bookings/Orders'),
    meta: {
      title: 'orders'
    },
  },
  {
    path: '/bookings/:bookingId/orders/:orderId',
    name: 'showOrder',
    component: () => import('@/views/bookings/Order'),
    meta: {
      title: 'order'
    },
  }
];

export default bookings;
