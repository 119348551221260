export default {
  namespaced: true,
  state: {
    title: null,
  },

  getters: {
    TITLE(state) {
      return state.title;
    }
  },

  mutations: {
    SET_TITLE(state, title) {
      state.title = title;
    }
  },
};
